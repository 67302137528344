<template>
  <BModal
    :active.sync="showModalConfirm"
    :on-cancel="cancelModal"
    has-modal-card
    trap-focus
    :can-cancel="false"
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal
  >
    <div class="modal-card modal-confirm" style="width: auto">
      <section class="modal-card-body">
        <div class="btn-container">
          <p class="question">{{ question }}</p>
          <div class="left-button">
            <div class="btn cancel-button" @click="cancelModal">{{ textCancel }}</div>
          </div>
          <div class="right-button">
            <div :class="{ 'disabled-btn': disabledConfirm }" class="confirm-button" @click="confirm">
              {{ textConfirm }}
            </div>
          </div>
        </div>
      </section>
    </div>
  </BModal>
</template>

<script>
import { BModal } from 'buefy/dist/esm/modal';

export default {
  name: 'Confirm',
  components: {
    BModal,
  },
  props: {
    showModalConfirm: {
      type: Boolean,
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
    textConfirm: {
      type: String,
      required: false,
      default: 'Cerrar',
    },
    textCancel: {
      type: String,
      required: false,
      default: 'Cancelar',
    },
  },
  data() {
    return {
      disabledConfirm: false,
    };
  },
  watch: {
    showModalConfirm(newValue) {
      window.parent.postMessage({ message: 'modalShown', isShown: newValue }, '*');
    },
  },
  methods: {
    async confirm() {
      if (!this.disabledConfirm) {
        this.disabledConfirm = true;
        await this.$emit('onConfirm');
        setTimeout(() => {
          this.cancelModal();
          this.disabledConfirm = false;
        }, 500);
      }
    },
    cancelModal() {
      this.$emit('onCancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-confirm {
  width: 376px;
  margin: 39px 45px 8px 27px;
  padding: 18px 28px 4px;
  border-radius: 17px;
  box-shadow: 0 17px 32px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
}
.question {
  width: 280px;
  height: 48px;
  margin: 0 18px 32px 19px;
  font-family: 'Avenir-Pro-Medium';
  font-size: 19px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: -0.11px;
  text-align: center;
  color: #494a4b;
  grid-area: question;
  grid-column: 1 / 3;
  grid-row: 1 / 2;
}

.btn-container {
  display: grid;
  margin: 0 auto;
  width: fit-content;
}

.disabled-btn {
  background-color: #979797 !important;
  cursor: not-allowed !important;
}

.confirm-button,
.cancel-button {
  width: 132px;
  height: 48px;
  border-radius: 6.1px;
  font-family: 'Circular-Std-Bold';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.11px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.left-button,
.right-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancel-button {
  border: solid 1px #979797;
  color: #494a4b;
}
.confirm-button {
  background-color: #e02020;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .modal-confirm {
    width: 60% !important;
    margin: auto !important;
    padding: 20px 5px 20px 5px !important;
    text-align: center;
  }
  .question {
    width: 90%;
    margin: 5px 5% 10px 5% !important;
  }

  .confirm-button,
  .cancel-button {
    width: 120px;
    height: 40px;
  }
}
@media screen and (max-width: 530px) {
  .modal-card-body {
    padding: 5px 5px 10px 5px !important;
  }
  .question {
    width: 90%;
    margin: 5px 5% 20px 5% !important;
  }
  .modal-confirm {
    width: 80% !important;
    margin: auto !important;
    padding: 20px 5px 20px 5px !important;
  }

  .cancel-button,
  .confirm-button {
    width: 120px;
    height: 30px;
    font-size: 18px;
  }
}
@media screen and (max-width: 360px) {
  .question {
    width: 90%;
    margin: 5px 5% 30px 5% !important;
  }
  .cancel-button {
    width: 80px;
    height: 30px;
    font-size: 14px;
  }
  .confirm-button {
    width: 80px;
    height: 30px;
    font-size: 14px;
  }
}
@media screen and (max-width: 250px) {
  .question {
    width: 90%;
    margin: 5px 5% 30px 5% !important;
  }

  .cancel-button,
  .confirm-button {
    width: 60px;
    height: 20px;
    font-size: 12px;
  }
}
</style>
