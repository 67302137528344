<template>
  <div>
    <div v-if="mode !== 'add'" class="cards-container">
      <template v-if="mode !== 'delete'">
        <div class="title-container">
          <span>Principal</span>
          <div v-if="otherCards.length > 0 && mode !== 'delete'" @click="prepareToDelete" class="delete-button">
            <span>Eliminar</span>
            <span class="icon">
              <i class="mdi mdi-delete-outline mdi-24px selected-icon"></i>
            </span>
          </div>
        </div>
        <div class="card-container" style="margin-bottom:20px">
          <div class="card-img">
            <img :src="mainCard.icon || '/icons/icn-card.png'" alt="card" />
          </div>
          <div class="card-description">
            <div>
              <span>{{ mainCard.type || '-' }}</span>
              <span>terminación</span>
              <span>{{ mainCard.lastFourt || '-' }}</span>
            </div>
            <div>
              <span>Expira:</span> <span>{{ mainCard.expiration || '-' }}</span>
            </div>
          </div>
        </div>
      </template>
      <div class="title-container">
        <span>{{ cardsTitle }}</span>
      </div>
      <div :key="key">
        <div v-for="(card, index) in otherCards" :key="index" class="card-container">
          <div v-if="mode === 'delete'" class="checkbox">
            <BRadio
              class="radio-select"
              v-model="cardToDelete"
              name="cardToDelete"
              size="is-small"
              :native-value="card.id"
            />
          </div>
          <div class="card-img">
            <img :src="card.icon" alt="card" />
          </div>
          <div class="card-description">
            <div>
              <span>{{ card.type }}</span>
              <span>terminación</span>
              <span>{{ card.lastFourt }}</span>
            </div>
            <div>
              <span>Expira:</span> <span>{{ card.expiration }}</span>
            </div>
          </div>
          <div class="switch-button" v-if="showChangePrimaryPaymentMethod">
            <span class="is-hidden-mobile">Principal</span>
            <span @click="confirmChangePaymentMethod(card.id)">
              <BSwitch />
            </span>
          </div>
        </div>
      </div>
      <div class="buttons-container">
        <button v-if="mode === 'view'" class="black-button" @click="onClickAddCard">+ Agregar Tarjeta</button>
        <button v-if="['add', 'delete'].includes(mode)" class="white-button" @click="cancel">Cancelar</button>
        <button v-if="mode === 'delete'" class="black-button" @click="confirmDeleteCard" :disabled="disabledDelete">
          Eliminar Tarjeta
        </button>
      </div>
    </div>
    <div v-else class="cards-container">
      <CardForm isProfile :isProvider="user.provider">
        <div class="buttons-container is-centered">
          <button class="black-button" @click="addNewCard">Guardar Tarjeta</button>
          <button class="white-button" @click="cancel">Cancelar</button>
        </div>
      </CardForm>
    </div>
    <AlertMessage :display.sync="displayAlert" :message="alertMessage" img="/assets/icons/icn-success-check.svg" />
    <Confirm
      :showModalConfirm="showModalConfirm"
      :question="question"
      @onConfirm="onConfirm"
      @onCancel="closeModalConfirm"
      textConfirm="Aceptar"
    />
  </div>
</template>

<script>
import CardForm from '@/views/Auth/CardForm';
import AlertMessage from '@/components/Elements/AlertMessage';
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import Confirm from '@/components/Modals/Confirm';
import bus from '@/bus';
import { BRadio } from 'buefy/dist/esm/radio';
import { BSwitch } from 'buefy/dist/esm/switch';

import {
  clickBtnMetodoDePagoPrincipal,
  clickBtnMetodoDePagoEliminar,
  clickBtnMetodoDePagoAgregarTarjeta,
} from '@/utils/analytics';

export default {
  name: 'AvailablePlans',
  components: {
    CardForm,
    AlertMessage,
    Confirm,
    BRadio,
    BSwitch,
  },
  data: () => ({
    mode: 'view',
    displayAlert: false,
    alertMessage: '',
    key: 0,
    cardToDelete: null,
    showModalConfirm: false,
    idPrimaryPaymentMethod: null,
    question: '',
    action: '',
  }),
  async created() {
    if (Object.keys(this.mainCard).length === 0) await this.getPaymentMethods();
  },
  mounted() {
    bus.$on('addNewCardSuccess', () => (this.mode = 'view'));
    this.setShowChangePrimaryPaymentMethod(true);
  },
  computed: {
    ...mapState('loginGeneral', ['user']),
    ...mapGetters('profileGeneral', ['mainCard', 'otherCards']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats', 'isLoggedIn']),
    ...mapState('profileGeneral', ['showChangePrimaryPaymentMethod']),
    cardsTitle() {
      return this.mode === 'delete' ? 'Mis Tarjetas' : 'Otras';
    },
    disabledDelete() {
      return !this.cardToDelete;
    },
  },
  methods: {
    ...mapActions('profileGeneral', ['getPaymentMethods', 'setPaymentMethodPrimary', 'deletePaymentMethod']),
    ...mapMutations('profileGeneral', ['setShowChangePrimaryPaymentMethod']),
    sendMessage(type) {
      let msg = '';
      switch (type) {
        case 'delete':
          msg = this.$mq !== 'mobile' ? 'Tarjeta Eliminada Existosamente' : 'Tarjeta Eliminada';
          break;
        case 'add':
          msg = this.$mq !== 'mobile' ? 'Tarjeta Agregada Existosamente' : 'Tarjeta Agregada';
          break;
      }
      this.alertMessage = msg;
      this.displayAlert = true;
      this.mode = 'view';
    },
    async addNewCard() {
      await bus.$emit('addNewCard');
    },
    confirmChangePaymentMethod(id) {
      clickBtnMetodoDePagoPrincipal(this.getPremiumAccount, this.getIsGolstats);
      this.idPrimaryPaymentMethod = id;
      this.question = '¿Esta seguro que desea cambiar el método de pago principal?';
      this.action = 'setPrimaryPaymentMethod';
      this.showModalConfirm = true;
    },
    onConfirm() {
      this[`${this.action}`]();
    },
    async setPrimaryPaymentMethod() {
      await this.setPaymentMethodPrimary(this.idPrimaryPaymentMethod);
      this.closeModalConfirm();
    },
    prepareToDelete() {
      clickBtnMetodoDePagoEliminar(this.getPremiumAccount, this.getIsGolstats);
      this.setShowChangePrimaryPaymentMethod(false);
      this.mode = 'delete';
    },
    confirmDeleteCard() {
      this.question = '¿Esta seguro que desea eliminar éste método de pago?';
      this.action = 'deleteCard';
      this.showModalConfirm = true;
    },
    async deleteCard() {
      if (this.cardToDelete) {
        try {
          await this.deletePaymentMethod(this.cardToDelete);
          this.mode = 'view';
          this.closeModalConfirm();
        } catch (e) {
          this.setShowChangePrimaryPaymentMethod(false);
          return Promise.reject(e);
        }
      }
    },
    cancel() {
      this.setShowChangePrimaryPaymentMethod(true);
      this.mode = 'view';
      this.cardToDelete = null;
      this.key++;
    },
    closeModalConfirm() {
      this.showModalConfirm = false;
      this.key++;
    },
    onClickAddCard() {
      clickBtnMetodoDePagoAgregarTarjeta(this.getPremiumAccount, this.getIsGolstats);
      this.mode = 'add';
    },
  },
};
</script>

<style lang="scss" scoped>
.cards-container {
  display: flex;
  flex-flow: column;

  .title-container {
    display: flex;
    letter-spacing: -0.09px;
    color: #494a4b;
    border-bottom: dashed 1px #9a9999;
    margin-bottom: 8px;
    padding: 7px 0px;
    text-align: left;
    span {
      font-family: Circular-Std-Medium;
      font-size: 17px;
      margin-right: auto;
    }
    .delete-button {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        font-size: 12px;
        margin-right: auto;
      }
    }
  }
  .card-container {
    border-radius: 4px;
    border: solid 1px #b3b3b3;
    height: 88px;
    margin: 7px 0px;
    background-color: #ffffff;
    display: flex;
    padding: 12px 0px 12px 12px;
    position: relative;
    align-items: center;

    .checkbox {
      position: absolute;
      top: 1px;
      left: 1px;
    }
    .card-img {
      width: 64px;
      height: 42px;
      border-radius: 6px;
      display: contents;
      padding-right: 12px;
      border: solid 1px #c5dbff;
    }
    .card-description {
      padding-left: 15px;
      display: flex;
      flex-flow: column;
      font-family: Avenir-Regular;
      font-size: 15px;
      align-items: flex-start;
      max-width: 300px;
      margin-right: auto;
      span {
        padding: 0px 3px;
      }
    }
    .switch-button {
      display: flex;
      span {
        font-family: Avenir-Regular;
        font-size: 12px;
        padding-right: 10px;
        line-height: 2.17;
        text-align: right;
        color: #494a4b;
      }
    }
  }
  .buttons-container {
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;

    &.is-centered {
      justify-content: center;
    }
    button {
      outline: none;
      border: none;
      font-family: Circular-Std-Medium;
      font-size: 20px;
      padding: 10px 40px;
      letter-spacing: -0.11px;
      text-align: center;
      margin-right: 20px;

      &:last-child {
        margin-right: 0px;
      }
    }
    .black-button {
      border-radius: 6.1px;
      background-color: #292929;
      color: #ffffff;
      cursor: pointer;
    }
    .black-button:hover {
      background-color: #020202;
      color: #d7d6d6;
    }
    .white-button {
      border-radius: 6.1px;
      border: solid 2px #989898;
      color: #292929;
      background-color: #ffffff;
      cursor: pointer;
    }
    .white-button:hover {
      color: #000000;
      background-color: #eae7e7;
    }
  }
}
@media screen and (max-width: 425px) {
  .switch-button {
    align-self: flex-end;
  }
  .cards-container {
    .card-container {
      border-radius: 8px;
      .card-description {
        padding-left: 5px;
        font-size: 3.2vw;
      }
    }
    .buttons-container {
      flex-flow: column;
      button {
        margin: 5px 0px;
      }
    }
  }
}
.radio-select {
  margin: 3px 0 0 3px;
}
</style>
