<template>
  <Transition name="fade">
    <div v-if="display" class="modal is-active" tabindex="1">
      <div class="modal-background"></div>
      <div class="animation-content">
        <div class="card">
          <img :src="img" :alt="img" />
          <div class="card-content">
            {{ message }}
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    img: {
      type: String,
      default: '',
    },
  },
  watch: {
    display: {
      inmediate: true,
      handler: function(val) {
        if (val) {
          setTimeout(
            function() {
              this.$emit('update:display', false);
            }.bind(this),
            1000,
          );
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: transparent;
  animation-duration: 3s;
}
.card {
  background-color: rgba(0, 0, 0, 0.9);
  color: #ffffff;
  display: flex;
  padding: 25px;
  justify-content: center;
  border-radius: 12px;
}
.card-content {
  max-height: 420px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: Circular-Std;
  font-size: 28px;
  line-height: 0.93;
  color: #ffffff;
}
.animation-content {
  max-width: 750px;
  padding: 2rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
@media screen and (max-width: 425px) {
  .card-content {
    padding: 1.5rem 0px;
  }
}
</style>
